<template>
  <b-card
    title="Lenon Ghana - Training Videos"
    :sub-title="$store.getters['app/isLargeScreen']?'These videos are intended to assist you in getting started with Lenon right away. We will continuously update this page as we create additional videos. Please feel free to reach out to our support team for any assistance you may need.':'To get started, click on the button below to watch our training videos on youtube.'"
  >
    <div class="row" v-if="$store.getters['app/isLargeScreen']">
      <div
        v-for="(path,index) in videoPaths"
        :key="index"
        class="col-3 mb-1"
      >
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="path"
          allowfullscreen
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <lenon-button
          label="View Directly On Youtube"
          @onClick="viewOnYoutube()"
        />
      </div>
    </div>
  </b-card>
</template>

<script>
import { BEmbed, BCard } from 'bootstrap-vue'
import LenonButton from '@/lenon/components/LenonButton'

export default {
  components: {
    LenonButton,
    BEmbed,
    BCard,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
    videoPath: {
      type: String,
      default: 'https://www.youtube.com/embed/zpOULjyy-n8?rel=0',
    },
  },
  data() {
    return {
      videoPaths: [
        'https://www.youtube.com/embed/cBEFKY-H_fE',
        'https://www.youtube.com/embed/MtOAVGdX-uE',
        'https://www.youtube.com/embed/fmyeAIex5P4?list=PLBbBwoLEbF5CjGWmZaggy95K8mtKkkR_y',
        'https://www.youtube.com/embed/wjbO_QXjG5g?list=PLBbBwoLEbF5CjGWmZaggy95K8mtKkkR_y',
        'https://www.youtube.com/embed/xM9BAdrAKFU?list=PLBbBwoLEbF5CjGWmZaggy95K8mtKkkR_y',
        'https://www.youtube.com/embed/TKSZ2wCWP-0?list=PLBbBwoLEbF5CjGWmZaggy95K8mtKkkR_y',
        'https://www.youtube.com/embed/A2i3qz2gzbY?list=PLBbBwoLEbF5CjGWmZaggy95K8mtKkkR_y',
        'https://www.youtube.com/embed/xu_QX20e1-o?list=PLBbBwoLEbF5CjGWmZaggy95K8mtKkkR_y',
        'https://www.youtube.com/embed/3Yig3B1hPSM?list=PLBbBwoLEbF5CjGWmZaggy95K8mtKkkR_y',
        'https://www.youtube.com/embed/A2Qf670X-So?list=PLBbBwoLEbF5CjGWmZaggy95K8mtKkkR_y',
        'https://www.youtube.com/embed/d5xryWY-05s',
        'https://www.youtube.com/embed/yI7p3JXb6-8?list=PLBbBwoLEbF5CjGWmZaggy95K8mtKkkR_y',
      ],
    }
  },
  methods: {
    viewOnYoutube() {
      window.open('https://www.youtube.com/playlist?list=PLBbBwoLEbF5CjGWmZaggy95K8mtKkkR_y', '_blank')
    },
  },
}
</script>
